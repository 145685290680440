// React & Next
import { useMemo } from 'react';

// 3rd
import { createFilter } from 'react-select';
import { Skeleton, forwardRef, Flex } from '@chakra-ui/react';
import type { ChakraComponent, StyleProps } from '@chakra-ui/react';

// App - Types
import type { KnowledgeSourceIntegration } from '../../types/knowledge-source-integration';

// App - Other
import Locale from '@/locale/en.json';
import { Select } from '@/components/molecules/form';
import { KnowledgeSourceIcon } from '@/components/translators/integration/knowledge-source';

const locale = Locale.features.integrations['select-knowledge-source-integration'];

type SelectKnowledgeSourceIntegrationComponent = ChakraComponent<
  'div',
  SelectKnowledgeSourceIntegrationProps
> & {
  Loading: typeof Loading;
};

type SelectKnowledgeSourceIntegrationProps = StyleProps & {
  knowledgeSourceIntegrations: KnowledgeSourceIntegration[];
  includeDefaultOption?: boolean | string;
  placeholder?: string;
  selected?: KnowledgeSourceIntegration;
  onChange: (selected?: KnowledgeSourceIntegration) => void;
  onBlur?: () => void;
  isDisabled?: boolean;
};

export const SelectKnowledgeSourceIntegration = forwardRef(
  (
    {
      knowledgeSourceIntegrations,
      includeDefaultOption,
      placeholder,
      selected,
      onChange,
      onBlur,
      isDisabled,
      ...props
    }: SelectKnowledgeSourceIntegrationProps,
    ref
  ) => {
    const defaultOption = useMemo(
      () => ({
        value: '',
        label:
          typeof includeDefaultOption === 'string'
            ? includeDefaultOption
            : locale['Select integration'],
      }),
      [includeDefaultOption]
    );

    const options = useMemo(() => {
      const _options = knowledgeSourceIntegrations
        .map((integration) => {
          return {
            value: integration.id,
            label: integration.displayName,
          };
        })
        .sort((a, b) =>
          a.label.localeCompare(b.label, undefined, {
            numeric: true,
          })
        );

      if (!!includeDefaultOption && _options.length > 1) return [defaultOption, ..._options];

      return _options;
    }, [defaultOption, includeDefaultOption, knowledgeSourceIntegrations]);

    const selectedOptions = useMemo(() => {
      if (!selected) return null;

      return {
        value: selected.id,
        label: selected.displayName,
      };
    }, [selected]);

    return (
      <Select
        useBasicStyles
        variant="outline"
        size="sm"
        isDisabled={isDisabled}
        options={options}
        value={selectedOptions}
        selectedOptionColor="surface.brand.primary"
        placeholder={placeholder}
        onBlur={onBlur}
        formatOptionLabel={(option) => {
          const integration = knowledgeSourceIntegrations.find(
            (integration) => integration.id === option.value
          );

          if (!integration) return null;

          return (
            <Flex alignItems="center" gap="sm">
              <KnowledgeSourceIcon source={integration.source} size="2xs" />

              {integration.displayName}
            </Flex>
          );
        }}
        onChange={(option) => {
          if (!option) {
            onChange();

            return;
          }

          const integration = knowledgeSourceIntegrations.find(
            (integration) => integration.id === option.value
          );

          if (!integration) return null;

          onChange(integration);
        }}
        ref={ref}
        closeMenuOnSelect={true}
        isClearable={false}
        filterOption={createFilter({
          stringify: (option) => option.label,
        })}
        chakraStyles={{
          container: (styles) => ({ ...styles, ...props }),
          menuList: (styles) => ({ ...styles, ...props }),
        }}
      />
    );
  }
) as SelectKnowledgeSourceIntegrationComponent;

type LoadingProps = StyleProps;

const Loading = ({ ...props }: LoadingProps) => {
  return <Skeleton {...props} height="26px" />;
};

SelectKnowledgeSourceIntegration.Loading = Loading;
