// React & Next
import { useMemo } from 'react';

// 3rd
import { createFilter } from 'react-select';
import { Skeleton, forwardRef } from '@chakra-ui/react';
import type { ChakraComponent, StyleProps } from '@chakra-ui/react';

// App - Other
import Locale from '@/locale/en.json';
import { Select } from '@/components/molecules/form';

const locale = Locale.features.integrations['select-azure-devops-work-item-project'];

type SelectAzureDevOpsWorkItemProjectComponent = ChakraComponent<
  'div',
  SelectAzureDevOpsWorkItemProjectProps
> & {
  Loading: typeof Loading;
};

type SelectAzureDevOpsWorkItemProjectProps = StyleProps & {
  projects: string[];
  includeDefaultOption?: boolean | string;
  placeholder?: string;
  selected?: string;
  onChange: (selected?: string) => void;
  onBlur?: () => void;
  isDisabled?: boolean;
};

export const SelectAzureDevOpsWorkItemProject = forwardRef(
  (
    {
      projects,
      includeDefaultOption,
      placeholder,
      selected,
      onChange,
      onBlur,
      isDisabled,
      ...props
    }: SelectAzureDevOpsWorkItemProjectProps,
    ref
  ) => {
    const defaultOption = useMemo(
      () => ({
        value: '',
        label:
          typeof includeDefaultOption === 'string'
            ? includeDefaultOption
            : locale['Select project'],
      }),
      [includeDefaultOption]
    );

    const options = useMemo(() => {
      const _options = projects
        .map((name) => {
          return {
            value: name,
            label: name,
          };
        })
        .sort((a, b) =>
          a.label.localeCompare(b.label, undefined, {
            numeric: true,
          })
        );

      if (!!includeDefaultOption && _options.length > 1) return [defaultOption, ..._options];

      return _options;
    }, [defaultOption, includeDefaultOption, projects]);

    const selectedOptions = useMemo(() => {
      if (!selected) return null;

      return {
        value: selected,
        label: selected,
      };
    }, [selected]);

    return (
      <Select
        useBasicStyles
        variant="outline"
        size="sm"
        isDisabled={isDisabled}
        options={options}
        value={selectedOptions}
        selectedOptionColor="surface.brand.primary"
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={(option) => {
          if (!option) {
            onChange();
          } else {
            onChange(option.value);
          }
        }}
        ref={ref}
        closeMenuOnSelect={true}
        isClearable={false}
        filterOption={createFilter({
          stringify: (option) => option.label,
        })}
        chakraStyles={{
          container: (styles) => ({ ...styles, ...props }),
          menuList: (styles) => ({ ...styles, ...props }),
        }}
      />
    );
  }
) as SelectAzureDevOpsWorkItemProjectComponent;

type LoadingProps = StyleProps;

const Loading = ({ ...props }: LoadingProps) => {
  return <Skeleton {...props} height="26px" />;
};

SelectAzureDevOpsWorkItemProject.Loading = Loading;
