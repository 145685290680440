// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { CreateSharePointOAuthIntegration } from '../types/share-point-integration';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { INTEGRATIONS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  code: z.string(),
  redirectUri: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCreateSharePointOAuthIntegrationRequestPayloadDto };
export type { RequestPayloadDto as CreateSharePointOAuthIntegrationRequestPayloadDto };

// #######
// Request
// #######

export const createSharePointOAuthIntegrationFromCode = async (
  model: CreateSharePointOAuthIntegration
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      code: model.code.trim(),
      redirectUri: model.redirectUri.trim(),
    });

    return await apiClient.post(
      `/Integration/InsertDelegatedPermissionsSharePointIntegration`,
      payload
    );
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateSharePointOAuthIntegration = {
  onCreateStart?: () => void;
  onCreateSuccess?: () => void;
  onCreateFailed?: (error: Error) => void;
};

export const useCreateSharePointOAuthIntegration = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseCreateSharePointOAuthIntegration) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (model: CreateSharePointOAuthIntegration) =>
      await createSharePointOAuthIntegrationFromCode(model),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: INTEGRATIONS_QUERY_KEYS.integrations(),
      });
    },
    onMutate: () => onCreateStart?.(),
    onSuccess: () => onCreateSuccess?.(),
    onError: (error) => onCreateFailed?.(error),
  });

  return {
    createIntegration: mutate,
    isCreatingIntegration: isPending,
    didIntegrationCreationErrored: isError,
  };
};
