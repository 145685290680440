// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { CreateSharePointOAuthUrl } from '../types/share-point-integration';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  redirectUri: z.string(),
  redirectUriPath: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCreateSharePointOAuthUrlRequestPayloadDto };
export type { RequestPayloadDto as CreateSharePointOAuthUrlRequestPayloadDto };

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  url: z.string(),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodCreateSharePointOAuthUrlResponseDto };
export type { ResponseDto as CreateSharePointOAuthUrlResponseDto };

// #######
// Request
// #######

export const createSharePointOAuthUrl = async (
  model: CreateSharePointOAuthUrl
): Promise<string> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      redirectUri: model.redirectUri.trim(),
      redirectUriPath: model.redirectUriPath.trim(),
    });
    const res = await apiClient.post(
      `/Integration/GetSharePointOAuth2DelegatedPermissionsLoginUrl`,
      payload
    );
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.url;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateSharePointOAuthUrl = {
  onCreateStart?: () => void;
  onCreateSuccess?: (authUrl: string) => void;
  onCreateFailed?: (error: Error) => void;
};

export const useCreateSharePointOAuthUrl = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseCreateSharePointOAuthUrl = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (model: CreateSharePointOAuthUrl) => await createSharePointOAuthUrl(model),
    onMutate: () => onCreateStart?.(),
    onSuccess: (authUrl) => onCreateSuccess?.(authUrl),
    onError: (error) => onCreateFailed?.(error),
  });

  return {
    createOAuthUrl: mutate,
    isCreatingOAuthUrl: isPending,
    didOAuthUrlCreationErrored: isError,
  };
};
